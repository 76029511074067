import { Skeleton } from '../../components/Skeleton'
import { TableCell, TableRow } from '../../components/Table'

interface StockItemsTableSkeletonProps {
  perPage: number
}

export function StockItemsTableSkeleton({
  perPage,
}: StockItemsTableSkeletonProps) {
  return (
    <>
      {Array.from({ length: perPage }).map((_, index) => (
        <TableRow key={index}>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[32px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[104px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[54px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[54px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[54px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent"></TableCell>
        </TableRow>
      ))}
    </>
  )
}
