/* eslint-disable react-hooks/exhaustive-deps */

import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import _ from 'lodash'
import { Search } from 'lucide-react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getRoles } from '../../../api/get-roles'
import { getTeam } from '../../../api/get-team'
import { getTeams } from '../../../api/get-teams'
import { getUsersOnTeam } from '../../../api/get-users-on-team'
import { Pagination } from '../../../components/Pagination'
import { Skeleton } from '../../../components/Skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { abbreviateName } from '../../../lib/abbreviate'
import { AddMemberModal } from './add-member-modal'
import { CreateTeamModal } from './create-team-modal'
import { EditMemberModal } from './edit-member-modal'
import { EditTeamModal } from './edit-team-modal'
import { RemoveMemberModal } from './remove-member-modal'
import { TeamsMembersTableSkeleton } from './team-members-table-skeleton'
import { TeamsSkeleton } from './teams-skeleton'

export function Teams() {
  const [searchParams, setSearchParams] = useSearchParams()

  const id = searchParams.get('id')

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const { data: result, isLoading } = useQuery({
    queryKey: ['teams'],
    queryFn: getTeams,
  })

  const { data: teamResult, isLoading: isLoadingTeam } = useQuery({
    queryKey: ['team', id],
    queryFn: () => getTeam({ id }),
  })

  const { data: usersOnTeamResult, isLoading: isLoadingUsers } = useQuery({
    queryKey: ['users-on-team', id, perPage, pageIndex],
    queryFn: () => getUsersOnTeam({ id, perPage, pageIndex }),
  })

  function handleSelectTeam(id: string) {
    setSearchParams((state) => {
      state.set('id', id)

      return state
    })
  }

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  useEffect(() => {
    if (!result) return

    if (id) return

    handleSelectTeam(result.teams[0].id)
  }, [result])

  return (
    <>
      <div className="space-y-5">
        <div className="px-4 md:px-0">
          <div className="relative flex flex-col border-b border-gray-100 pb-5 dark:border-gray-750 md:flex-row md:py-0 md:pb-5">
            <div>
              <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
                Equipes
              </h1>
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-375">
                Gerencie e visualize suas equipes.
              </p>
            </div>
            {roles && (
              <div className="mt-4 flex gap-3 md:ml-auto md:mt-0 md:items-start">
                <div className="flex gap-3">
                  {roles.isAdmin && <CreateTeamModal />}
                  {(roles.leaderOnTeams.some((team) => team.id === id) ||
                    roles.isAdmin) && <AddMemberModal />}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="gap-8 px-4 pt-3 md:grid md:grid-cols-team md:px-0">
          <div>
            <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
              Em equipes
            </h1>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-375">
              Você está atualmente nessas equipes.
            </p>
          </div>
          <div className="pt-5 md:pt-0">
            {result &&
              result.teams.map((team) => (
                <div
                  key={team.id}
                  onClick={() => handleSelectTeam(team.id)}
                  className="flex items-center gap-3 border-b border-gray-100 py-4 first:border-t dark:border-gray-750 md:first:border-t-0"
                >
                  <div className="group flex flex-1 cursor-pointer items-center gap-3">
                    <div
                      className={clsx(
                        'flex h-10 w-10 items-center justify-center rounded-full bg-purple-600 text-sm font-semibold text-white transition duration-300 ease-in-out',
                        {
                          'ring ring-purple-600 ring-opacity-30':
                            team.id === id,
                          '!bg-gray-80 !text-gray-400 !ring-gray-100 !ring-opacity-40 dark:!bg-gray-750 dark:!text-gray-350 dark:!ring-gray-750 dark:!ring-opacity-30':
                            !team.active,
                        },
                      )}
                    >
                      {abbreviateName(String(team.name))}
                    </div>
                    <div className="flex flex-col">
                      <h1
                        className={clsx(
                          'text-sm font-semibold text-gray-700 transition-colors dark:text-gray-150',
                          {
                            'dark:text-gray-75': team.id === id,
                          },
                        )}
                      >
                        {team?.name}
                      </h1>
                    </div>
                    <div className="ml-auto">
                      <EditTeamModal />
                    </div>
                  </div>
                </div>
              ))}
            {isLoading && <TeamsSkeleton />}
          </div>
        </div>
        <div className="gap-8 pt-3 md:grid md:grid-cols-team md:pt-0">
          <div className="px-4 md:px-0">
            {teamResult && (
              <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
                {teamResult.team.name}
              </h1>
            )}
            {isLoadingTeam && <Skeleton className="mb-3 h-5 w-32 rounded-md" />}
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-375">
              Gerencie sua equipe e altere funções/permissões.
            </p>
          </div>
          <div>
            <Table className="mt-6 md:mt-0">
              <TableHeader>
                <TableHead>Nome</TableHead>
                <TableHead>Email</TableHead>
                <TableHead />
              </TableHeader>
              <TableBody>
                {usersOnTeamResult &&
                  usersOnTeamResult.users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell className="text-gray-900 dark:text-white">
                        {user.name}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {(roles?.isAdmin ||
                          roles?.leaderOnTeams.some(
                            (team) => team.id === teamResult?.team.id,
                          )) && (
                          <div className="flex justify-end gap-3">
                            <RemoveMemberModal user={user} />
                            <EditMemberModal user={user} />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                {isLoadingUsers && <TeamsMembersTableSkeleton perPage={8} />}
              </TableBody>
            </Table>
            {_.isEmpty(usersOnTeamResult?.users) && !isLoadingUsers && (
              <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
                <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
                  <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
                </div>
                <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
                  Nenhum membro na equipe
                </h1>
                <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
                  Tente novamente ou adicione um membro a equipe.
                </p>
              </div>
            )}
            {usersOnTeamResult && (
              <Pagination
                onPageChange={handlePaginate}
                pageIndex={usersOnTeamResult.meta.pageIndex}
                totalCount={usersOnTeamResult.meta.totalCount}
                totalInPage={usersOnTeamResult.users.length}
                perPage={usersOnTeamResult.meta.perPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
