import { api } from '../lib/axios'

export interface RegisterStockItemBody {
  description: string
  category: string
  stockId: string
  amount: number
  metric: string
}

export async function registerStockItem({
  description,
  category,
  stockId,
  amount,
  metric,
}: RegisterStockItemBody) {
  await api.post('/stock/items', {
    description,
    category,
    stockId,
    amount,
    metric,
  })
}
