import { api } from '../lib/axios'

export interface GetTicketActivitiesQuery {
  id: string
}

export interface GetTicketActivitiesResponse {
  activities: {
    id: string
    createdBy: {
      name: string
      avatarUrl: string
    }
    created_at: string
    action: string
    type: string
    file: {
      filename: string
      size: number
    }
    title: string
    oldTitle: string
    description: string
    oldLocation: string
    location: string
    oldPriority: string
    priority: string
    coordinates: string
    status: {
      name: string
      type: string
    }
    oldStatus: {
      name: string
      type: string
    }
    category: {
      name: string
    }
    oldCategory: {
      name: string
    }
    user: {
      name: string
    }
    oldUser: {
      name: string
    }
    team: {
      name: string
      color: 'purple' | 'green' | 'blue' | 'yellow' | 'red' | 'gray'
    }
    oldTeam: {
      name: string
      color: 'purple' | 'green' | 'blue' | 'yellow' | 'red' | 'gray'
    }
    item: {
      description: string
      metric: string
      stock: {
        name: string
      }
    }
    quantity: number
  }[]
}

export async function getTicketActivities({ id }: GetTicketActivitiesQuery) {
  const response = await api.get<GetTicketActivitiesResponse>(
    `/activities/${id}`,
  )

  return response.data
}
