import { Slot } from '@radix-ui/react-slot'
import { forwardRef, HTMLAttributes } from 'react'
import { tv, VariantProps } from 'tailwind-variants'

const avatar = tv({
  base: [
    'flex h-10 w-10 select-none items-center justify-center rounded-full text-sm font-semibold',
  ],
  variants: {
    variant: {
      outline:
        'dark:bg-gray-750 dark:text-gray-150 border dark:border-white/10 bg-gray-80 border-black/10',
      primary: 'bg-purple-600 text-white',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export interface AvatarProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof avatar> {
  asChild?: boolean
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ asChild, variant, className, ...props }, ref) => {
    const Component = asChild ? Slot : 'div'

    return (
      <Component
        {...props}
        className={avatar({ variant, className })}
        ref={ref}
      />
    )
  },
)

Avatar.displayName = 'Avatar'

export { Avatar }
