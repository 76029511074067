import { api } from '../lib/axios'

export interface UpdateStockBody {
  stock: {
    id: number
  }
  name: string
  members: {
    id: string
  }[]
  active: boolean
}

export async function updateStock({
  stock,
  name,
  members,
  active,
}: UpdateStockBody) {
  await api.patch('/stock', {
    stock,
    name,
    members,
    active,
  })
}
