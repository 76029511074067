import { TableHead } from '../../../components/Table'

export function MetricsHeaderTableSkeleton() {
  return (
    <>
      <TableHead />
      <TableHead />
      <TableHead />
      <TableHead />
      <TableHead />
    </>
  )
}
