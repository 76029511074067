import { SVGProps } from 'react'

export function Google(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="fill-current"
        d="M12.478 23.04C6.39 23.04 1.436 18.088 1.436 12 1.436 5.912 6.39.96 12.478.96c2.758 0 5.398 1.022 7.436 2.878l.372.34-3.642 3.64-.337-.288a5.893 5.893 0 00-3.829-1.42A5.898 5.898 0 006.587 12a5.898 5.898 0 005.891 5.89c2.342 0 4.193-1.197 5.065-3.235h-5.545v-4.97l10.825.014.08.381c.565 2.68.113 6.62-2.175 9.44-1.894 2.335-4.669 3.52-8.25 3.52z"
      />
    </svg>
  )
}
