import { api } from '../lib/axios'

export interface UpdateStockItemsBody {
  ticketId: string | null
  items:
    | {
        id: number
        quantity: number
      }[]
    | null
}

export async function updateStockItems({
  ticketId,
  items,
}: UpdateStockItemsBody) {
  await api.patch('/stock/items', {
    ticketId,
    items,
  })
}
