import { Skeleton } from '../../../components/Skeleton'
import { TableCell, TableRow } from '../../../components/Table'

interface StockTableSkeletonProps {
  perPage: number
}

export function StockTableSkeleton({ perPage }: StockTableSkeletonProps) {
  return (
    <>
      {Array.from({ length: perPage }).map((_, index) => (
        <TableRow key={index}>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[54px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[104px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <Skeleton className="my-1 h-4 w-[54px] rounded-md" />
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent">
            <div className="flex">
              <Skeleton className="fisrt:ml-0 -ml-2 h-10 w-10 rounded-full" />
              <Skeleton className="fisrt:ml-0 -ml-2 h-10 w-10 rounded-full" />
              <Skeleton className="fisrt:ml-0 -ml-2 h-10 w-10 rounded-full" />
              <Skeleton className="fisrt:ml-0 -ml-2 h-10 w-10 rounded-full" />
              <Skeleton className="fisrt:ml-0 -ml-2 h-10 w-10 rounded-full" />
            </div>
          </TableCell>
          <TableCell className="group-hover/tableRow:!bg-transparent"></TableCell>
        </TableRow>
      ))}
    </>
  )
}
