import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AlertCircle, CheckCircle, Clock, Plus, Search, X } from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { getTeams } from '../../../api/get-teams'
import { registerStatus } from '../../../api/register-status'
import { Button } from '../../../components/Button'
import * as Combobox from '../../../components/Form/Combobox'
import * as Input from '../../../components/Form/Input'
import * as Select from '../../../components/Form/Select'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'

const createStatusSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  type: z.enum(['wait', 'analysis', 'finished'], {
    required_error: 'Tipo é obrigatório',
  }),
  team: z
    .object({
      id: z.string().uuid(),
    })
    .optional(),
})

export type CreateStatusProps = z.infer<typeof createStatusSchema>

export function CreateStatusModal() {
  const [open, setOpen] = useState<boolean>()

  const {
    reset,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<CreateStatusProps>({
    resolver: zodResolver(createStatusSchema),
  })

  const { data: result } = useQuery({
    queryKey: ['teams'],
    queryFn: getTeams,
  })

  const { mutateAsync: registerStatusFn } = useMutation({
    mutationKey: ['register-status'],
    mutationFn: registerStatus,
    onSuccess() {
      queryClient.refetchQueries({ queryKey: ['ticket-status'] })
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast t={t} title="Status registrado com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar registrar o status, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleCreateStatus(data: CreateStatusProps) {
    const { name, type, team } = data

    registerStatusFn({
      name,
      type,
      team,
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        if (!value) {
          reset()
        }

        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>
        <Button className="flex items-center gap-1.5">
          <Plus className="h-5 w-5" />
          Criar status
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950"
        >
          <div className="flex justify-between">
            <div className="rounded-md border border-gray-100 p-2.5 dark:border-gray-750">
              <AlertCircle className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <Dialog.Cancel
              tabIndex={-1}
              className="self-start rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Criar Status
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Insira um nome para seu novo status
          </Dialog.Description>
          <form
            className="mt-5 flex flex-col gap-4"
            onSubmit={handleSubmit(handleCreateStatus)}
          >
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Nome*
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control
                      {...field}
                      placeholder="e.g. Aguardando Compra"
                    />
                  </Input.Root>
                )}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Tipo*
              </label>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select.Root
                    {...field}
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger>
                      <Select.Value placeholder="Selecione o tipo" />
                    </Select.Trigger>

                    <Select.Content>
                      <Select.Item value="wait">
                        <Select.ItemText>
                          <Clock className="ml-auto h-4 w-4" />
                          Aguardando
                        </Select.ItemText>
                      </Select.Item>
                      <Select.Item value="analysis">
                        <Select.ItemText>
                          <Search className="ml-auto h-4 w-4" />
                          Em análise
                        </Select.ItemText>
                      </Select.Item>
                      <Select.Item value="finished">
                        <Select.ItemText>
                          <CheckCircle className="ml-auto h-4 w-4" />
                          Finalizado
                        </Select.ItemText>
                      </Select.Item>
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Equipe
              </label>
              <Controller
                name="team"
                control={control}
                render={({ field }) => (
                  <Combobox.Root {...field}>
                    <Combobox.Trigger placeholder="Selecione a equipe" />

                    <Combobox.Content>
                      {result &&
                        result.teams.map((team) => (
                          <Combobox.Option key={team.id} value={team}>
                            {team.name}
                          </Combobox.Option>
                        ))}
                    </Combobox.Content>
                  </Combobox.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col-reverse gap-3 md:flex-row">
              <Dialog.Cancel asChild>
                <Button variant="outline" className="flex-1" type="button">
                  Cancelar
                </Button>
              </Dialog.Cancel>
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!isValid}
              >
                Criar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
