import * as Dialog from '@radix-ui/react-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import {
  Check,
  ChevronRight,
  Minus,
  Package,
  Plus,
  Search,
  X,
} from 'lucide-react'
import { FormEvent, ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'sonner'

import { getAvailableStock, Stock } from '../../../api/get-available-stock'
import {
  getStockItemsPerCategory,
  Item,
} from '../../../api/get-stock-items-per-category'
import { updateStockItems } from '../../../api/update-stock-items'
import { Button } from '../../../components/Button'
import * as Input from '../../../components/Form/Input'
import { Skeleton } from '../../../components/Skeleton'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'

interface StockModalProps {
  children: ReactNode
}

export interface SelectedItem {
  item: Item
  quantity: number
}

export function StockModal({ children }: StockModalProps) {
  const [open, setOpen] = useState(false)

  const [selectedStock, setSelectedStock] = useState<Stock | null>(null)
  const [search, setSearch] = useState('')
  const [searchItems, setSearchItems] = useState('')

  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([])

  const { ticketId } = useParams()

  const { data: result, isLoading } = useQuery({
    queryKey: ['get-available-stock', search],
    queryFn: () =>
      getAvailableStock({
        name: search || null,
      }),
  })

  const { data: resultStockItems } = useQuery({
    queryKey: ['get-stock-items-per-category', selectedStock, searchItems],
    queryFn: () =>
      getStockItemsPerCategory({
        stockId: selectedStock ? String(selectedStock.id) : null,
        description: searchItems || null,
      }),
    enabled: !!selectedStock,
  })

  const { mutateAsync: updateStockItemsFn } = useMutation({
    mutationKey: ['update-stock-items'],
    mutationFn: updateStockItems,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['get-stock-items-per-category'] })
      queryClient.refetchQueries({ queryKey: ['ticket-activity', ticketId] })
      setSelectedStock(null)
      setSelectedItems([])
      setOpen(false)
      toast.custom((t) => (
        <Toast
          t={t}
          title="Estoque atualizado com sucesso!"
          variant="success"
        />
      ))
    },

    onError: () => {
      setSelectedStock(null)
      setSelectedItems([])
      setOpen(false)
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar adicionar/remover itens do estoque, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  function handleUpdateStockItem() {
    updateStockItemsFn({
      ticketId: ticketId || null,
      items:
        selectedItems.map(({ item, quantity }) => {
          return {
            id: item.id,
            quantity,
          }
        }) || null,
    })
  }

  function handleRemoveItem(stockItem: Item, amount: number) {
    if (selectedItems.length) {
      const selectedItem = selectedItems.find(
        ({ item }) => item.id === stockItem.id,
      )

      if (selectedItem) {
        setSelectedItems((state) => {
          return state.map((selectedItem) => {
            if (selectedItem.item.id === stockItem.id) {
              return {
                item: selectedItem.item,
                quantity: -amount,
              }
            }

            return selectedItem
          })
        })
      } else {
        setSelectedItems((state) => [
          ...state,
          {
            item: stockItem,
            quantity: -amount,
          },
        ])
      }
    } else {
      setSelectedItems([
        {
          item: stockItem,
          quantity: -amount,
        },
      ])
    }
  }

  function handleAddItem(stockItem: Item, amount: number) {
    if (selectedItems.length) {
      const selectedItem = selectedItems.find(
        ({ item }) => item.id === stockItem.id,
      )

      if (selectedItem) {
        if (selectedItem.quantity + amount > stockItem.amount) return

        setSelectedItems((state) => {
          return state.map((selectedItem) => {
            if (selectedItem.item.id === stockItem.id) {
              return {
                item: selectedItem.item,
                quantity: amount,
              }
            }

            return selectedItem
          })
        })
      } else {
        setSelectedItems((state) => [
          ...state,
          {
            item: stockItem,
            quantity: amount,
          },
        ])
      }
    } else {
      setSelectedItems([
        {
          item: stockItem,
          quantity: amount,
        },
      ])
    }
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          setSelectedStock(null)
          setSelectedItems([])
        }

        setOpen(open)
      }}
    >
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed right-0 top-0 z-10 flex min-h-screen w-full max-w-md flex-col bg-white scrollbar-none data-[state=closed]:animate-slideLeftAndFade data-[state=open]:animate-slideRightAndFade dark:bg-gray-950 md:max-w-xl md:border-l md:border-gray-100 md:dark:border-gray-750"
          asChild
        >
          <aside>
            {selectedStock ? (
              <>
                <div className="flex items-center justify-between px-6 pt-6 dark:bg-gray-950">
                  <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-25">
                    Estoque: {selectedStock.name}
                  </h1>
                  <Dialog.Close asChild>
                    <Button className="p-2.5" variant="ghost">
                      <X className="h-5 w-5 text-gray-600 dark:text-gray-375" />
                    </Button>
                  </Dialog.Close>
                </div>
                <div className="mt-6 px-6">
                  <Input.Root>
                    <Input.Prefix>
                      <Search className="h-5 w-5" />
                    </Input.Prefix>
                    <Input.Control
                      onBlur={(e) => setSearchItems(e.currentTarget.value)}
                      onKeyDown={(e) =>
                        e.key === 'Enter' &&
                        setSearchItems(e.currentTarget.value)
                      }
                      placeholder="Buscar"
                    />
                  </Input.Root>
                </div>
                <main className="mt-6 flex flex-1 flex-col px-6 pb-6">
                  {resultStockItems?.stock.map((item) => (
                    <>
                      <label className="text-sm font-medium text-gray-600 dark:text-gray-125">
                        {item.category}
                      </label>
                      <div className="my-4 space-y-4">
                        {item.items.map((item) => (
                          <ItemCard
                            key={item.id}
                            item={item}
                            removeItem={handleRemoveItem}
                            addItem={handleAddItem}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                          />
                        ))}
                      </div>
                    </>
                  ))}
                  {!!selectedItems.length && (
                    <div className="mt-auto space-y-4 border-t pt-6 dark:border-gray-750">
                      {selectedItems.map((selectedItem) => {
                        const isAdd = selectedItem.quantity > 0

                        return (
                          <div
                            key={selectedItem.item.id}
                            className="flex justify-between text-sm font-medium dark:text-gray-125"
                          >
                            {isAdd ? 'Adicionar' : 'Remover'}{' '}
                            {selectedItem.item.description} ao estoque{' '}
                            <span
                              className={clsx({
                                'text-red-500': !isAdd,
                                'text-green-500': isAdd,
                              })}
                            >
                              {isAdd && '+'}
                              {selectedItem.quantity}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  )}
                  {!resultStockItems?.stock.length && !isLoading && (
                    <p className="text-center text-sm text-gray-700 dark:text-gray-125">
                      Nenhum item encontrado.
                    </p>
                  )}
                </main>
                <footer className="mt-auto border-t px-6 py-4 dark:border-gray-750">
                  <div className="flex justify-end gap-3">
                    <Button
                      variant="outline"
                      onClick={() => setSelectedItems([])}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleUpdateStockItem}
                      disabled={!selectedItems.length}
                    >
                      Confirmar
                    </Button>
                  </div>
                </footer>
              </>
            ) : (
              <>
                <div className="flex items-center justify-between px-6 pt-6 dark:bg-gray-950">
                  <h1 className="text-xl font-semibold text-gray-900 dark:text-gray-25">
                    Selecione o estoque
                  </h1>
                  <Dialog.Close asChild>
                    <Button className="p-2.5" variant="ghost">
                      <X className="h-5 w-5 text-gray-600 dark:text-gray-375" />
                    </Button>
                  </Dialog.Close>
                </div>
                <div className="mt-6 px-6">
                  <Input.Root>
                    <Input.Prefix>
                      <Search className="h-5 w-5" />
                    </Input.Prefix>
                    <Input.Control
                      onBlur={(e) => setSearch(e.currentTarget.value)}
                      onKeyDown={(e) =>
                        e.key === 'Enter' &&
                        setSearchItems(e.currentTarget.value)
                      }
                      placeholder="Buscar"
                    />
                  </Input.Root>
                </div>
                <main className="mt-6 space-y-6 px-6">
                  {result?.stock.map((stock) => (
                    <div
                      key={stock.id}
                      className="flex cursor-pointer items-center gap-3"
                      onClick={() => setSelectedStock(stock)}
                    >
                      <div className="flex h-12 w-12 items-center justify-center rounded-full border border-black/10 bg-gray-80 text-gray-300 dark:border-white/10 dark:bg-gray-750 dark:text-gray-150">
                        <Package className="h-6 w-6" />
                      </div>
                      <p className="text-sm font-semibold text-gray-700 dark:text-gray-125">
                        {stock.name}
                      </p>
                      <Button variant="ghost" className="ml-auto">
                        <ChevronRight className="h-5 w-5" />
                      </Button>
                    </div>
                  ))}
                  {!result && isLoading && (
                    <>
                      <div className="flex cursor-pointer items-center gap-3">
                        <Skeleton className="flex h-12 w-12 items-center justify-center rounded-full" />
                        <Skeleton className="h-4 w-36 rounded-md" />
                        <Button variant="ghost" className="ml-auto">
                          <ChevronRight className="h-5 w-5" />
                        </Button>
                      </div>
                      <div className="flex cursor-pointer items-center gap-3">
                        <Skeleton className="flex h-12 w-12 items-center justify-center rounded-full" />
                        <Skeleton className="h-4 w-36 rounded-md" />
                        <Button variant="ghost" className="ml-auto">
                          <ChevronRight className="h-5 w-5" />
                        </Button>
                      </div>
                      <div className="flex cursor-pointer items-center gap-3">
                        <Skeleton className="flex h-12 w-12 items-center justify-center rounded-full" />
                        <Skeleton className="h-4 w-36 rounded-md" />
                        <Button variant="ghost" className="ml-auto">
                          <ChevronRight className="h-5 w-5" />
                        </Button>
                      </div>
                    </>
                  )}
                  {!result?.stock.length && !isLoading && (
                    <p className="text-center text-sm text-gray-700 dark:text-gray-125">
                      Nenhum estoque encontrado.
                    </p>
                  )}
                </main>
              </>
            )}
          </aside>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

interface ItemCardProps {
  item: Item
  removeItem: (stockItem: Item, amount: number) => void
  addItem: (stockItem: Item, amount: number) => void
  selectedItems: SelectedItem[]
  setSelectedItems: React.Dispatch<React.SetStateAction<SelectedItem[]>>
}

function ItemCard({
  item,
  addItem,
  removeItem,
  selectedItems,
  setSelectedItems,
}: ItemCardProps) {
  const [action, setAction] = useState<'add' | 'remove' | null>(null)
  const [addAmount, setAddAmount] = useState('')
  const [removeAmount, setRemoveAmount] = useState('')

  const selectedItem = selectedItems.find(
    ({ item: selectedItem }) => selectedItem.id === item.id,
  )

  function handleAddItem(e: FormEvent) {
    e.preventDefault()

    addItem(item, Number(addAmount))
    setAction(null)
    setAddAmount('')
  }

  function handleRemoveItem(e: FormEvent) {
    e.preventDefault()

    removeItem(item, Number(removeAmount))
    setAction(null)
    setRemoveAmount('')
  }

  useEffect(() => {
    if (action) {
      setSelectedItems((state) =>
        state.filter((stockItem) => stockItem.item.id !== item.id),
      )
    }
  }, [action])

  return (
    <>
      <div key={item.id} className="flex items-center">
        <div>
          <p className="text-sm font-semibold text-gray-600 dark:text-gray-125">
            {item.description}
          </p>
          <span className="text-sm text-gray-300 dark:text-gray-150">
            {item.amount} {item.amount > 1 ? item.metric + 's' : item.metric}
          </span>
        </div>
        <div className="ml-auto">
          <Button
            onClick={() => {
              if (action === 'remove') {
                setAction(null)
              } else {
                setAction('remove')
              }
            }}
            className="p-3"
            variant="ghost"
            disabled={
              selectedItem ? selectedItem.quantity === -item.amount : false
            }
          >
            <Minus className="h-5 w-5" />
          </Button>
          <Button
            onClick={() => {
              if (action === 'add') {
                setAction(null)
              } else {
                setAction('add')
              }
            }}
            className="p-3"
            variant="ghost"
          >
            <Plus className="h-5 w-5" />
          </Button>
        </div>
      </div>
      {action === 'add' && (
        <div className="flex items-center justify-between py-2">
          <p className="text-sm font-medium text-gray-600 dark:text-gray-125">
            Quantos itens gostaria de{' '}
            <span className="text-green-500">adicionar</span> ao estoque?
          </p>
          <form onSubmit={handleAddItem} className="flex gap-2">
            <Input.Root>
              <Input.Control
                onChange={(e) => setAddAmount(e.currentTarget.value)}
                className="w-36 text-right"
                type="number"
                min={1}
              />
            </Input.Root>
            <Button
              variant="outline"
              type="submit"
              className="flex w-10 items-center justify-center p-0"
              disabled={!addAmount}
            >
              <Check className="h-4 w-4" />
            </Button>
          </form>
        </div>
      )}
      {action === 'remove' && (
        <div className="flex items-center justify-between py-2">
          <p className="text-sm font-medium text-gray-600 dark:text-gray-125">
            Quantos itens gostaria de{' '}
            <span className="text-red-500">remover</span> do estoque?
          </p>
          <form onSubmit={handleRemoveItem} className="flex gap-2">
            <Input.Root>
              <Input.Control
                onChange={(e) => setRemoveAmount(e.currentTarget.value)}
                className="w-36 text-right"
                type="number"
                max={item.amount}
                min={1}
              />
            </Input.Root>
            <Button
              variant="outline"
              className="flex w-10 items-center justify-center p-0"
              disabled={!removeAmount}
            >
              <Check className="h-4 w-4" />
            </Button>
          </form>
        </div>
      )}
    </>
  )
}
