import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { Plus, Users, X } from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { addMemberToTicket } from '../../../../api/add-member-to-ticket'
import { getUsersAddMemberForm } from '../../../../api/get-users-add-member-form'
import { Button } from '../../../../components/Button'
import * as Combobox from '../../../../components/Form/Combobox'
import { Toast } from '../../../../components/Toast'
import { abbreviateName } from '../../../../lib/abbreviate'
import { queryClient } from '../../../../lib/react-query'

export const addMemberToCallSchema = z.object({
  members: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string(),
      email: z.string().email(),
    }),
    {
      required_error: 'Selecione pelo menos um membro',
    },
  ),
})

export type AddMemberToCallProps = z.infer<typeof addMemberToCallSchema>

export function AddMemberModal() {
  const [open, setOpen] = useState<boolean>()

  const { ticketId } = useParams()

  const { control, watch, setValue, handleSubmit, reset } =
    useForm<AddMemberToCallProps>({
      resolver: zodResolver(addMemberToCallSchema),
    })

  const { data: usersQuery } = useQuery({
    queryKey: ['users-ticket-form'],
    queryFn: () => getUsersAddMemberForm({ id: ticketId ?? '' }),
  })

  const members = watch('members')

  const { mutateAsync: addMemberToTicketFn } = useMutation({
    mutationKey: ['add-member-to-ticket'],
    mutationFn: addMemberToTicket,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['users-ticket-form'] })
      queryClient.refetchQueries({ queryKey: ['ticket-participants'] })
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Membro(s) adicionado(s) com sucesso!"
          variant="success"
        />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar adicionar o(s) membro(s), tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleAddMemberToCall(data: AddMemberToCallProps) {
    const { members } = data

    addMemberToTicketFn({
      id: ticketId ?? '',
      members,
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        if (!value) {
          reset()
        }
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>
        <Button className="mt-4 flex gap-1 md:ml-auto md:mt-0 md:self-start">
          <Plus className="h-5 w-5" />
          Adicionar
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950">
          <div className="flex justify-between">
            <div className="rounded-md border border-gray-100 p-2.5 dark:border-gray-750">
              <Users className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <Dialog.Close className="self-start rounded-lg p-2 hover:bg-gray-50 focus:shadow-none dark:hover:bg-gray-750">
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Close>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Adicionar membros ao chamado
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Por favor selecione os usuários
          </Dialog.Description>
          <div
            className={clsx('max-h-[9.75rem] space-y-3 overflow-y-auto pr-4', {
              'mt-5': members?.length,
            })}
          >
            {members?.map((user) => (
              <div key={user.id} className="flex items-center gap-3">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-purple-600 text-sm font-medium text-white">
                  {abbreviateName(user.name)}
                </div>
                <div>
                  <p className="text-sm font-semibold text-gray-700 dark:text-gray-25">
                    {user.name}
                  </p>
                  <span className="text-sm text-gray-600 dark:text-gray-375">
                    {user.email}
                  </span>
                </div>
                <button
                  onClick={() => {
                    setValue(
                      'members',
                      members.filter((item) => item.id !== user.id),
                    )
                  }}
                  className="ml-auto text-sm font-semibold text-red-800 transition-colors hover:text-red-900 dark:text-red-300 dark:hover:text-red-200"
                >
                  Remover
                </button>
              </div>
            ))}
          </div>
          <form className="mt-5" onSubmit={handleSubmit(handleAddMemberToCall)}>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Usuários
              </label>
              <Controller
                name="members"
                control={control}
                render={({ field }) => (
                  <Combobox.Root {...field} multiple>
                    <Combobox.Trigger placeholder="Selecione os usuários" />

                    <Combobox.Content>
                      {usersQuery &&
                        usersQuery.users.map((user) => (
                          <Combobox.Option key={user.id} value={user}>
                            {user.name}
                          </Combobox.Option>
                        ))}
                    </Combobox.Content>
                  </Combobox.Root>
                )}
              />
            </div>
            <div className="mt-8 flex flex-col-reverse gap-3 md:flex-row">
              <Dialog.Close asChild>
                <Button variant="outline" className="flex-1">
                  Cancelar
                </Button>
              </Dialog.Close>
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!members?.length}
              >
                Salvar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
