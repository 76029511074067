import * as Tabs from '@radix-ui/react-tabs'
import { motion } from 'framer-motion'
import { SquareArrowOutUpRight } from 'lucide-react'
import { Link } from 'react-router-dom'

interface TabItemProps {
  value: string
  title: string
  layoutId: string
  isSelected: boolean
  count?: number
  target?: string
}

export function TabItem({
  title,
  value,
  isSelected,
  count,
  layoutId,
  target,
}: TabItemProps) {
  return (
    <Tabs.Trigger
      value={value}
      className="group relative flex h-8 items-center px-1 pb-5 text-sm font-medium leading-5 text-gray-300 outline-none transition-colors data-[state=active]:text-purple-700 hover:text-purple-700 focus:shadow-none dark:text-gray-375 dark:data-[state=active]:text-gray-125 dark:hover:text-gray-125"
    >
      <span className="whitespace-nowrap rounded text-sm font-semibold">
        {title}
      </span>
      {target && (
        <Link target="_blank" to={target} className="ml-2">
          <SquareArrowOutUpRight className="h-4 w-4" />
        </Link>
      )}
      {!!count && (
        <div className="ml-2 rounded-full border border-gray-100 bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 transition-colors group-hover:bg-purple-50 group-hover:text-purple-700 group-data-[state=active]:bg-purple-50 group-data-[state=active]:text-purple-700 dark:border-gray-725 dark:bg-gray-850 dark:text-gray-375 dark:group-hover:border-purple-800 dark:group-hover:bg-purple-900 dark:group-hover:text-purple-300 dark:group-data-[state=active]:border-purple-800 dark:group-data-[state=active]:bg-purple-900 dark:group-data-[state=active]:text-purple-300">
          {count}
        </div>
      )}

      {isSelected && (
        <motion.div
          layoutId={layoutId}
          className="absolute -bottom-px left-0 right-0 h-0.5 bg-purple-700 dark:bg-gray-150"
        />
      )}
    </Tabs.Trigger>
  )
}
