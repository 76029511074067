import { api } from '../lib/axios'

interface DownloadMetricsReportQuery {
  from: Date
  to: Date
}

export async function downloadMetricsReport({
  from,
  to,
}: DownloadMetricsReportQuery) {
  const response = await api.get('/metrics/report', {
    params: {
      from,
      to,
    },
    responseType: 'arraybuffer',
  })

  return response.data
}
