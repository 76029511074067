import { api } from '../lib/axios'

export interface Stock {
  id: number
  name: string
}

interface GetAvailableStockQuery {
  name: string | null
}

export interface GetAvailableStockResponse {
  stock: Stock[]
}

export async function getAvailableStock({ name }: GetAvailableStockQuery) {
  const response = await api.get<GetAvailableStockResponse>(
    '/stock/available',
    {
      params: {
        name,
      },
    },
  )

  return response.data
}
