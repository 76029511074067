/* eslint-disable @typescript-eslint/no-explicit-any */
import { Slot } from '@radix-ui/react-slot'
import clsx from 'clsx'
import { ChevronDown, ChevronUp } from 'lucide-react'
import {
  cloneElement,
  Dispatch,
  JSXElementConstructor,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Tooltip } from '../../components/Tooltip'

export interface ItemProps {
  icon?: ReactElement<any, string | JSXElementConstructor<any>>
  title: string
  path: string[]
  subpath?: string
  className?: string
  isClosed?: boolean
  target?: string
  isDropdown?: boolean
  isSub?: boolean
  isSearch?: boolean
  sub?: ItemProps[]
  setWidth?: Dispatch<SetStateAction<number>>
}

export function Item({
  icon,
  title,
  className,
  path,
  isClosed,
  isDropdown,
  sub,
  isSub,
  isSearch,
  setWidth,
}: ItemProps) {
  const location = useLocation()

  const [open, setOpen] = useState(() => {
    if (isDropdown) {
      if (location.pathname.startsWith(path.join(''))) {
        return true
      }
    }
    if (isClosed) {
      return false
    }

    return false
  })

  function handleOpenDropdown() {
    if (isClosed) {
      if (setWidth) {
        setWidth(312)
      }
    }

    setOpen(!open)
  }

  useEffect(() => {
    if (isClosed) {
      setOpen(false)
    }
  }, [isClosed])

  if (isDropdown) {
    return (
      <Tooltip side="right" description={title} hidden={!isClosed} asChild>
        <div
          className={clsx('w-full', {
            '!w-fit': isClosed,
          })}
        >
          <div
            className={clsx(
              'group flex w-full cursor-pointer items-center gap-2 rounded-md px-3 py-2 font-semibold text-gray-700 transition-colors hover:bg-gray-50 hover:text-gray-900 dark:text-gray-150 dark:hover:bg-gray-750 dark:hover:text-gray-75',
              {
                'bg-gray-50 text-gray-900 dark:bg-gray-750 dark:text-gray-75':
                  location.pathname.startsWith(path[0]),
                '!w-fit': isClosed,
              },
            )}
            onClick={handleOpenDropdown}
          >
            {icon &&
              cloneElement(icon, {
                className: clsx(
                  'w-6 h-6 text-gray-300 group-hover:text-gray-700 dark:text-gray-375 dark:group-hover:text-gray-75',
                  {
                    'text-gray-700 dark:text-gray-75':
                      location.pathname.startsWith(path[0]),
                  },
                ),
              })}
            {!isClosed && title}
            <>
              {!isClosed && (
                <>
                  {open ? (
                    <ChevronUp className="ml-auto h-5 w-5" />
                  ) : (
                    <ChevronDown className="ml-auto h-5 w-5" />
                  )}
                </>
              )}
            </>
          </div>
          <div
            className={clsx('flex w-full flex-col gap-1', {
              hidden: !open,
            })}
          >
            {sub?.map((item) => (
              <Item
                key={item.path.join('')}
                icon={item.icon}
                title={item.title}
                path={item.path}
                isDropdown={item.isDropdown}
                isSub
              />
            ))}
          </div>
        </div>
      </Tooltip>
    )
  }

  return (
    <Tooltip side="right" description={title} hidden={!isClosed} asChild>
      <Slot>
        <Link
          className={clsx(
            'group w-full rounded-md transition-colors hover:bg-gray-50 focus:shadow-gray dark:hover:bg-gray-750',
            {
              'bg-gray-50 dark:bg-gray-750': isSub
                ? location.pathname === path[0]
                : isSearch
                  ? location.pathname + location.search === path.join('')
                  : location.pathname.startsWith(path[0]),
              '!w-fit': isClosed,
              'pl-4 first:mt-1 last:mb-1': isSub,
              className,
            },
          )}
          to={path.join('')}
        >
          <div
            className={clsx(
              'flex items-center gap-2 px-3 py-2 font-semibold text-gray-700 hover:text-gray-900 dark:text-gray-150 dark:hover:text-gray-75',
              {
                'text-gray-900 dark:text-gray-75': isSub
                  ? location.pathname === path[0]
                  : isSearch
                    ? location.pathname + location.search === path.join('')
                    : location.pathname.startsWith(path[0]),
              },
            )}
          >
            {icon &&
              cloneElement(icon, {
                className: clsx(
                  'w-6 h-6 text-gray-300 group-hover:text-gray-700 dark:text-gray-375 dark:group-hover:text-gray-75',
                  {
                    'text-gray-700 dark:text-gray-75': isSub
                      ? location.pathname === path[0]
                      : isSearch
                        ? location.pathname + location.search === path.join('')
                        : location.pathname.startsWith(path[0]),
                    '!w-5 !h-5': title === 'Google Sheets',
                  },
                ),
              })}
            {!isClosed && <p className="truncate">{title}</p>}
          </div>
        </Link>
      </Slot>
    </Tooltip>
  )
}
