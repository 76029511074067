import { useQuery } from '@tanstack/react-query'
import { ChevronRight, Package } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet, useNavigate } from 'react-router-dom'

import { getStockLayout } from '../../api/get-stock-layout'
import * as Select from '../../components/Form/Select'
import { Item } from '../_layouts/sidebar-item'

export function StockLayout() {
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const [selectedItem, setSelectedItem] = useState<string>()

  const { data: result } = useQuery({
    queryKey: ['get-stock-layout'],
    queryFn: getStockLayout,
  })

  useEffect(() => {
    if (!selectedItem) return

    if (selectedItem === 'all') {
      navigate('/stock/all')
    } else if (!isNaN(Number(selectedItem))) {
      navigate(`/stock/${selectedItem}`)
    } else {
      navigate(`/stock/all?category=${selectedItem}`)
    }
  }, [selectedItem])

  if (isMobile) {
    return (
      <div>
        <div className="mx-4 pt-8">
          <Select.Root
            value={selectedItem}
            onValueChange={setSelectedItem}
            defaultValue="all"
          >
            <Select.Trigger>
              <Select.Value />
            </Select.Trigger>

            <Select.Content>
              <Select.Item value="all">
                <Select.ItemText>Todos</Select.ItemText>
              </Select.Item>
              {result &&
                result.stock.map((stock) => (
                  <Select.Item key={stock.id} value={String(stock.id)}>
                    <Select.ItemText>{stock.name}</Select.ItemText>
                  </Select.Item>
                ))}
              {result &&
                result.categories.map((category) => (
                  <Select.Item key={category} value={category}>
                    <Select.ItemText>{category}</Select.ItemText>
                  </Select.Item>
                ))}
            </Select.Content>
          </Select.Root>
        </div>
        <Outlet />
      </div>
    )
  }

  return (
    <div className="flex">
      <div className="flex min-h-screen w-full max-w-xs flex-col gap-1 border-r border-gray-100 p-4 pt-8 dark:border-gray-750">
        <Item icon={<Package />} path={['/stock/all']} title="Todos os itens" />
        {!!result?.stock.length && (
          <label className="mb-2 mt-6 text-sm font-medium text-gray-700 dark:text-gray-375">
            Estoque
          </label>
        )}
        {result &&
          result.stock.map((stock) => (
            <Item
              key={stock.id}
              icon={<Package />}
              path={[`/stock/${stock.id}`]}
              title={stock.name}
            />
          ))}
        {!!result?.categories.length && (
          <label className="mb-2 mt-6 text-sm font-medium text-gray-700 dark:text-gray-375">
            Categorias
          </label>
        )}
        {result &&
          result.categories.map((category, index) => (
            <Item
              key={index}
              icon={<ChevronRight />}
              path={[`/stock/all?category=${category}`]}
              title={category}
              isSearch
            />
          ))}
      </div>
      <div className="flex-1 p-8">
        <Outlet />
      </div>
    </div>
  )
}
