import { api } from '../lib/axios'

export interface Stock {
  id: number
  name: string
}

export interface GetStockLayoutResponse {
  stock: Stock[]
  categories: string[]
}

export async function getStockLayout() {
  const response = await api.get<GetStockLayoutResponse>('/layout/stock')

  return response.data
}
