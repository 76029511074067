import { api } from '../lib/axios'

export interface RegisterCategoryBody {
  name: string
  sla: string
  team: {
    id: string
  }
  status: {
    id: string
  }
  description?: string
  fillLocation: boolean
  ableToStock: boolean
}

export async function registerCategory({
  name,
  sla,
  team,
  status,
  description,
  fillLocation,
  ableToStock,
}: RegisterCategoryBody) {
  await api.post('/categories', {
    name,
    sla,
    team,
    status,
    description,
    fillLocation,
    ableToStock,
  })
}
