import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { PackagePlus, X } from 'lucide-react'
import { ReactNode, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { getAvailableStock } from '../../api/get-available-stock'
import { registerStockItem } from '../../api/register-stock-item'
import { Button } from '../../components/Button'
import * as Input from '../../components/Form/Input'
import * as Select from '../../components/Form/Select'
import { Toast } from '../../components/Toast'
import { queryClient } from '../../lib/react-query'

interface CreateStockItemModalProps {
  children: ReactNode
}

export const createStockItemSchema = z.object({
  description: z.string().min(3),
  category: z.string().min(3),
  stockId: z.string(),
  amount: z
    .string()
    .min(1)
    .transform((amount) => Number(amount)),
  metric: z.string(),
})

export type createStockItemProps = z.infer<typeof createStockItemSchema>

export function CreateStockItemModal({ children }: CreateStockItemModalProps) {
  const [open, setOpen] = useState<boolean>()

  const {
    watch,
    reset,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<createStockItemProps>({
    resolver: zodResolver(createStockItemSchema),
    defaultValues: {
      metric: 'unidade',
    },
  })

  const { mutateAsync: registerStockItemFn } = useMutation({
    mutationKey: ['register-stock-item'],
    mutationFn: registerStockItem,
    onSuccess() {
      queryClient.refetchQueries({ queryKey: ['get-stock-items'] })
      queryClient.refetchQueries({ queryKey: ['get-stock-layout'] })
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast t={t} title="Item criado com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar criar o item, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  function handleCreateStockItem(data: createStockItemProps) {
    const { description, category, amount, metric, stockId } = data

    registerStockItemFn({
      description,
      category,
      amount,
      metric,
      stockId,
    })
  }

  const { data: result } = useQuery({
    queryKey: ['get-available-stock'],
    queryFn: () =>
      getAvailableStock({
        name: null,
      }),
  })

  const amount = watch('amount')

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        if (!value) {
          reset()
        }
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950">
          <div className="flex justify-between">
            <div className="rounded-full bg-purple-600 p-3">
              <PackagePlus className="h-6 w-6 text-gray-100" />
            </div>
            <Dialog.Cancel className="self-start rounded-lg p-2 hover:bg-gray-50 focus:shadow-none dark:hover:bg-gray-750">
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Crie um item
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Preencha as informações do item abaixo
          </Dialog.Description>
          <form className="mt-5" onSubmit={handleSubmit(handleCreateStockItem)}>
            <div className="flex flex-col gap-1.5">
              <label
                htmlFor="description"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                Descrição*
              </label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control {...field} placeholder="RB 2011" />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label
                htmlFor="category"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                Categoria*
              </label>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control {...field} placeholder="Switch" />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label
                htmlFor="stockId"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                Estoque*
              </label>
              <Controller
                name="stockId"
                control={control}
                render={({ field }) => (
                  <Select.Root
                    {...field}
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger>
                      <Select.Value placeholder="Selecione o estoque" />
                    </Select.Trigger>

                    <Select.Content>
                      {result &&
                        result.stock.map((stock) => (
                          <Select.Item key={stock.id} value={String(stock.id)}>
                            <Select.ItemText>{stock.name}</Select.ItemText>
                          </Select.Item>
                        ))}
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label
                htmlFor="amount"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                Quantidade*
              </label>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <Input.Root className="py-0 pr-0">
                    <Input.Control
                      {...field}
                      type="number"
                      placeholder="1-999"
                      min={1}
                    />
                    <Controller
                      name="metric"
                      control={control}
                      render={({ field: selectField }) => (
                        <Select.Root
                          {...selectField}
                          value={selectField.value}
                          onValueChange={selectField.onChange}
                        >
                          <Select.Trigger className="w-fit rounded-none rounded-r-lg border-0 border-l">
                            <Select.Value placeholder="unidade" />
                          </Select.Trigger>

                          <Select.Content>
                            <Select.Item value="unidade">
                              <Select.ItemText>
                                unidade{amount > 1 && 's'}
                              </Select.ItemText>
                            </Select.Item>
                            <Select.Item value="metro">
                              <Select.ItemText>
                                metro{amount > 1 && 's'}
                              </Select.ItemText>
                            </Select.Item>
                          </Select.Content>
                        </Select.Root>
                      )}
                    />
                  </Input.Root>
                )}
              />
            </div>

            <div className="mt-8 flex gap-3">
              <Dialog.Cancel asChild>
                <Button variant="outline" className="flex-1">
                  Cancelar
                </Button>
              </Dialog.Cancel>
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!isValid}
              >
                Criar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
