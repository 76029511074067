import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { Edit2, Plus, Search } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getRoles } from '../../../api/get-roles'
import { getStock } from '../../../api/get-stock'
import { Avatar } from '../../../components/Avatar'
import { Button } from '../../../components/Button'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { Tag } from '../../../components/Tag'
import { Tooltip } from '../../../components/Tooltip'
import { abbreviateName } from '../../../lib/abbreviate'
import { CreateStockModal } from './create-stock-modal'
import { EditStockModal } from './edit-stock-modal'
import { StockTableSkeleton } from './stock-table-skeleton'

export function Stock() {
  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const { data: result, isLoading } = useQuery({
    queryKey: ['get-stock', pageIndex, perPage],
    queryFn: () => getStock({ pageIndex, perPage }),
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  return (
    <>
      <div className="px-4 pb-5 md:flex md:px-0">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
              Gerenciamento de estoque
            </h1>
            <div className="rounded-full border border-purple-200 bg-purple-50 px-2 py-0.5 text-xs font-medium text-purple-600 dark:border-purple-800 dark:bg-purple-900 dark:text-purple-300">
              {result?.meta.totalCount || 0} estoques
            </div>
          </div>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-150">
            Gerencie estoques e seus usuários
          </p>
        </div>
        {roles?.isAdmin && (
          <div className="mt-4 flex gap-3 md:ml-auto md:mt-0 md:self-start">
            <CreateStockModal>
              <Button className="flex items-center gap-1.5">
                <Plus className="h-5 w-5" />
                Criar
              </Button>
            </CreateStockModal>
          </div>
        )}
      </div>
      <Table>
        <TableHeader>
          <TableHead className="w-20">ID</TableHead>
          <TableHead>Nome</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Usuários</TableHead>
          <TableHead />
        </TableHeader>
        <TableBody>
          {result &&
            result.stock.map((stock) => {
              return (
                <TableRow key={stock.id}>
                  <TableCell className="font-mono font-medium text-gray-900 dark:text-gray-25">
                    #{stock.id}
                  </TableCell>
                  <TableCell className="font-medium text-gray-900 dark:text-gray-25">
                    {stock.name}
                  </TableCell>
                  <TableCell>
                    {stock.active ? (
                      <Tag variant="green" className="flex items-center gap-1">
                        <div className="h-1.5 w-1.5 rounded-full bg-green-600" />
                        Ativo
                      </Tag>
                    ) : (
                      <Tag variant="gray" className="flex items-center gap-1">
                        <div className="h-1.5 w-1.5 rounded-full bg-gray-600 dark:bg-gray-350" />
                        Inativo
                      </Tag>
                    )}
                  </TableCell>
                  <TableCell>
                    <div className="flex">
                      {stock.users.map((user, index) => {
                        if (index > 4) return null

                        return (
                          <Tooltip
                            classNames={{
                              trigger:
                                'rounded-full cursor-default fisrt:ml-0 -ml-2',
                            }}
                            key={user.id}
                            description={user.name}
                          >
                            <Avatar variant="outline">
                              {abbreviateName(user.name)}
                            </Avatar>
                          </Tooltip>
                        )
                      })}
                      {stock.users.length > 5 && (
                        <Tooltip
                          classNames={{
                            trigger:
                              'rounded-full cursor-default fisrt:ml-0 -ml-2',
                          }}
                          description={`${stock.users.reduce(
                            (acc, value, index) => {
                              if (index < 5) return acc

                              if (index === stock.users.length - 1) {
                                acc += value.name
                              } else {
                                acc += `${value.name} - `
                              }

                              return acc
                            },
                            '',
                          )}`}
                        >
                          <Avatar variant="outline">
                            {`+${stock.users.length - 5}`}
                          </Avatar>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {roles?.isAdmin && (
                      <div className="flex justify-end">
                        <EditStockModal stock={stock}>
                          <Button variant="ghost" className="p-2">
                            <Edit2 className="h-5 w-5" />{' '}
                          </Button>
                        </EditStockModal>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          {!result && isLoading && <StockTableSkeleton perPage={perPage} />}
        </TableBody>
      </Table>
      {_.isEmpty(result?.stock) && !isLoading && (
        <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
          <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
            <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
          </div>
          <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
            Nenhum estoque encontrado
          </h1>
          <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
            Tente novamente ou adicione um estoque.
          </p>
        </div>
      )}
      {result && (
        <Pagination
          onPageChange={handlePaginate}
          pageIndex={result.meta.pageIndex}
          totalCount={result.meta.totalCount}
          totalInPage={result.stock.length}
          perPage={result.meta.perPage}
        />
      )}
    </>
  )
}
