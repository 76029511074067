import clsx from 'clsx'
import { ArrowDown, ArrowUp } from 'lucide-react'
import { ComponentProps, ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'

interface TableHeadProps extends ComponentProps<'th'> {
  name?: string
  children?: ReactNode
}

export function TableHead({ children, name, ...rest }: TableHeadProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const sort = searchParams.get('sort')
  const key = searchParams.get('key')

  function handleOrderBy() {
    if (!name) return

    setSearchParams((state) => {
      if (key !== name) {
        state.delete('key')
        state.set('sort', 'asc')
        state.set('key', name)
      }

      if (!sort) {
        state.set('sort', 'asc')
        state.set('key', name)
      } else if (sort === 'asc') {
        state.set('sort', 'desc')
      } else {
        state.delete('sort')
        state.delete('key')
      }

      return state
    })
  }

  if (name) {
    return (
      <th
        className={clsx(
          'select-none whitespace-nowrap border-y border-gray-100 bg-gray-50 py-3 pl-6 pr-2 text-xs font-normal text-gray-600 dark:border-gray-750 dark:bg-gray-850 dark:text-gray-150 md:first:rounded-tl-xl md:first:border-l md:last:rounded-tr-xl md:last:border-r',
          rest.className,
        )}
      >
        <div
          onClick={handleOrderBy}
          className={clsx('flex items-center gap-1.5', {
            'cursor-pointer': name,
          })}
        >
          {children}
          {sort === 'asc' && key === name ? (
            <ArrowDown className="h-4 w-4" />
          ) : sort === 'desc' && key === name ? (
            <ArrowUp className="h-4 w-4" />
          ) : null}
        </div>
      </th>
    )
  }

  return (
    <th
      className={clsx(
        'select-none whitespace-nowrap border-y border-gray-100 bg-gray-50 py-3 pl-6 pr-2 text-left text-xs font-normal text-gray-600 dark:border-gray-750 dark:bg-gray-850 dark:text-gray-150 md:first:rounded-tl-xl md:first:border-l md:last:rounded-tr-xl md:last:border-r',
        rest.className,
      )}
    >
      {children}
    </th>
  )
}
