import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { Info, UserCog2, X } from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { getTeam } from '../../../api/get-team'
import { updateTeam } from '../../../api/update-team'
import { Button } from '../../../components/Button'
import * as Input from '../../../components/Form/Input'
import { Switch } from '../../../components/Switch'
import { Toast } from '../../../components/Toast'
import { Tooltip } from '../../../components/Tooltip'
import { queryClient } from '../../../lib/react-query'

const editTeamSchema = z.object({
  name: z.string(),
  active: z.boolean(),
  tickets: z.boolean(),
  appearsOnTicketForm: z.boolean(),
  autoFillMembersOnTicketForm: z.boolean(),
  incidents: z.boolean(),
  statusPage: z.boolean(),
  editIncidentModels: z.boolean(),
  integrationIXC: z.boolean(),
  stock: z.boolean(),
  integrationGoogleSheets: z.boolean(),
})

type EditTeamProps = z.infer<typeof editTeamSchema>

type Permissions = {
  tickets: boolean
  appearsOnTicketForm: boolean
  autoFillMembersOnTicketForm: boolean
  incidents: boolean
  statusPage: boolean
  editIncidentModels: boolean
  integrationIXC: boolean
  stock: boolean
  integrationGoogleSheets: boolean
}

export function EditTeamModal() {
  const [open, setOpen] = useState<boolean>()

  const [searchParams] = useSearchParams()

  const id = searchParams.get('id')

  const { data: teamResult } = useQuery({
    queryKey: ['edit-team', id],
    queryFn: () => getTeam({ id }),
    enabled: open,
  })

  const permissions = teamResult?.team?.permissions.reduce(
    (acc, { name }) => ({ ...acc, [name]: true }),
    {
      tickets: false,
      appearsOnTicketForm: false,
      autoFillMembersOnTicketForm: false,
      incidents: false,
      statusPage: false,
      editIncidentModels: false,
      integrationIXC: false,
      stock: false,
      integrationGoogleSheets: false,
    } as Record<string, boolean>,
  ) as Record<keyof Permissions, boolean>

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = useForm<EditTeamProps>({
    resolver: zodResolver(editTeamSchema),
    values: {
      name: teamResult?.team.name ?? '',
      active: teamResult?.team.active ?? true,
      ...permissions,
    },
  })

  const [appearsOnTicketForm, active] = watch(['appearsOnTicketForm', 'active'])

  const { mutateAsync: updateTeamFn } = useMutation({
    mutationKey: ['update-team'],
    mutationFn: updateTeam,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['edit-team', id] })
      queryClient.refetchQueries({ queryKey: ['teams'] })
      queryClient.refetchQueries({ queryKey: ['roles'] })
      setOpen(false)
      toast.custom((t) => (
        <Toast t={t} title="Equipe atualizada com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar atualizar a equipe, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleEditTeam(data: EditTeamProps) {
    const { name, active, ...rest } = data

    const connect = Object.entries(rest).reduce((acc, [key, value]) => {
      if (value) {
        acc.push(key)
      }

      return acc
    }, [] as string[])

    const disconnect = Object.entries(rest).reduce((acc, [key, value]) => {
      if (!value) {
        acc.push(key)
      }

      return acc
    }, [] as string[])

    updateTeamFn({
      team: {
        id: teamResult?.team.id ?? '',
      },
      name,
      active,
      connect,
      disconnect,
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        reset({
          name: teamResult?.team.name ?? '',
          active: teamResult?.team.active ?? true,
          ...permissions,
        })
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>
        <Button
          variant="ghost"
          className="rounded-lg !bg-transparent p-0 text-sm font-semibold text-gray-600 dark:text-gray-150"
        >
          Editar
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950"
        >
          <div className="flex justify-between">
            <div className="rounded-md border border-gray-100 p-2.5 dark:border-gray-750">
              <UserCog2 className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <Dialog.Close
              tabIndex={-1}
              className="self-start rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Close>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Editar {teamResult?.team?.name}
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Atualize as permissões da equipe
          </Dialog.Description>
          <form className="mt-5" onSubmit={handleSubmit(handleEditTeam)}>
            <div className="flex flex-col gap-1.5">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                Nome*
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control {...field} />
                  </Input.Root>
                )}
              />
            </div>

            <div className="mt-4 flex items-center gap-2">
              <Switch id="active" name="active" control={control} />

              <label
                htmlFor="active"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                {active ? 'Ativo' : 'Inativo'}
              </label>
            </div>

            <div className="mt-6 flex flex-col gap-2">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Chamados
              </label>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <Switch id="tickets" name="tickets" control={control} />

                  <label
                    htmlFor="tickets"
                    className="text-sm font-medium text-gray-700 dark:text-gray-125"
                  >
                    Visualizar Chamados
                  </label>
                  <Tooltip
                    description="Membros da equipe conseguem acessar a funcionalidade de chamados através da barra de navegação lateral"
                    asChild
                  >
                    <Info className="h-4 w-4 cursor-help text-gray-700 dark:text-gray-125" />
                  </Tooltip>
                </div>
                <div className="flex items-center gap-2">
                  <Switch
                    id="appearsOnTicketForm"
                    name="appearsOnTicketForm"
                    control={control}
                  />

                  <label
                    htmlFor="appearsOnTicketForm"
                    className="text-sm font-medium text-gray-700 dark:text-gray-125"
                  >
                    Disponível na abertura de chamados
                  </label>
                  <Tooltip
                    description="A equipe fica disponível para ser selecionada na abertura de chamados, caso não seja selecionada essa opção, não será possível abrir chamados para essa equipe atráves do formulário"
                    asChild
                  >
                    <Info className="h-4 w-4 cursor-help text-gray-700 dark:text-gray-125" />
                  </Tooltip>
                </div>
                <div
                  className={clsx('flex items-center gap-2', {
                    hidden: !appearsOnTicketForm,
                  })}
                >
                  <Switch
                    id="autoFillMembersOnTicketForm"
                    name="autoFillMembersOnTicketForm"
                    control={control}
                  />

                  <label
                    htmlFor="autoFillMembersOnTicketForm"
                    className="text-sm font-medium text-gray-700 dark:text-gray-125"
                  >
                    Preencher participantes automaticamente
                  </label>
                  <Tooltip
                    description="Ao selecionar essa equipe na abertura de um chamado, todos os mebros dessa equipe serão marcados como participantes"
                    asChild
                  >
                    <Info className="h-4 w-4 cursor-help text-gray-700 dark:text-gray-125" />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="mt-6 flex flex-col gap-4">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Incidentes
              </label>
              <div className="flex items-center gap-2">
                <Switch id="incidents" name="incidents" control={control} />

                <label
                  htmlFor="incidents"
                  className="text-sm font-medium text-gray-700 dark:text-gray-125"
                >
                  Registrar Incidentes
                </label>
                <Tooltip
                  description="Membros da equipe conseguem registrar incidentes, através de um botão no canto superior direito no painel de Incidentes"
                  asChild
                >
                  <Info className="h-4 w-4 cursor-help text-gray-700 dark:text-gray-125" />
                </Tooltip>
              </div>
              <div className="flex items-center gap-2">
                <Switch
                  id="editIncidentModels"
                  name="editIncidentModels"
                  control={control}
                />

                <label
                  htmlFor="editIncidentModels"
                  className="text-sm font-medium text-gray-700 dark:text-gray-125"
                >
                  Editar modelos e scripts
                </label>
                <Tooltip
                  description="Membros da equipe conseguem criar modelos e gerenciar scripts."
                  asChild
                >
                  <Info className="h-4 w-4 cursor-help text-gray-700 dark:text-gray-125" />
                </Tooltip>
              </div>
            </div>
            <div className="mt-6 flex flex-col gap-4">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Integrações
              </label>
              <div className="flex items-center gap-2">
                <Switch
                  id="integrationIXC"
                  name="integrationIXC"
                  control={control}
                />

                <label
                  htmlFor="integrationIXC"
                  className="text-sm font-medium text-gray-700 dark:text-gray-125"
                >
                  Visualizar IXC
                </label>
                <Tooltip
                  description="Membros da equipe conseguem acessar as funcionalidades integradas ao IXCSoft"
                  asChild
                >
                  <Info className="h-4 w-4 cursor-help text-gray-700 dark:text-gray-125" />
                </Tooltip>
              </div>
              <div className="flex items-center gap-2">
                <Switch
                  id="integrationGoogleSheets"
                  name="integrationGoogleSheets"
                  control={control}
                />

                <label
                  htmlFor="integrationGoogleSheets"
                  className="text-sm font-medium text-gray-700 dark:text-gray-125"
                >
                  Visualizar Google Sheets
                </label>
                <Tooltip
                  description="Membros da equipe conseguem acessar as funcionalidades integradas ao Google Sheets"
                  asChild
                >
                  <Info className="h-4 w-4 cursor-help text-gray-700 dark:text-gray-125" />
                </Tooltip>
              </div>
            </div>
            <div className="mt-6 flex flex-col gap-2">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Página de Status
              </label>
              <div className="flex items-center gap-2">
                <Switch id="statusPage" name="statusPage" control={control} />

                <label
                  htmlFor="statusPage"
                  className="text-sm font-medium text-gray-700 dark:text-gray-125"
                >
                  Registrar alertas
                </label>
                <Tooltip
                  description="Membros da equipe conseguem registrar alertas a página https://status.raimax.com.br"
                  asChild
                >
                  <Info className="h-4 w-4 cursor-help text-gray-700 dark:text-gray-125" />
                </Tooltip>
              </div>
            </div>
            <div className="mt-6 flex flex-col gap-2">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                Estoque
              </label>
              <div className="flex items-center gap-2">
                <Switch id="stock" name="stock" control={control} />

                <label
                  htmlFor="stock"
                  className="text-sm font-medium text-gray-700 dark:text-gray-125"
                >
                  Visualizar Estoque
                </label>
                <Tooltip
                  description="Membros da equipe conseguem acessar a funcionalidade de estoque através da barra de navegação lateral"
                  asChild
                >
                  <Info className="h-4 w-4 cursor-help text-gray-700 dark:text-gray-125" />
                </Tooltip>
              </div>
            </div>
            <div className="mt-8 flex gap-3">
              <Dialog.Close asChild>
                <Button variant="outline" className="flex-1">
                  Cancelar
                </Button>
              </Dialog.Close>
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!isDirty}
              >
                Salvar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
