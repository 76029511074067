import { api } from '../lib/axios'

interface GetMetricsQuery {
  from?: Date | null
  to?: Date | null
}

export interface GetMetricsResponse {
  metricsPerStatus: {
    columns: string[]
    colaborators: {
      name: string
      total: number
      status: {
        name: string
        value: number
      }[]
    }[]
  }
}

export async function getMetrics({ from, to }: GetMetricsQuery) {
  const response = await api.get<GetMetricsResponse>('/metrics', {
    params: {
      from,
      to,
    },
  })

  return response.data
}
