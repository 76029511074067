import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getRoles } from '../../../api/get-roles'
import { getSettingsLayout } from '../../../api/get-settings-layout-count'
import { getUsers } from '../../../api/get-users'
import { Avatar } from '../../../components/Avatar'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { Tag } from '../../../components/Tag'
import { abbreviateName } from '../../../lib/abbreviate'
import { CreateUserModal } from './create-user-modal'
import { EditUserModal } from './edit-user-modal'
import { UsersTableSkeleton } from './users-table-skeleton'

export function Users() {
  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const { data: layoutResult } = useQuery({
    queryKey: ['layout-settings-count'],
    queryFn: getSettingsLayout,
  })

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const { data: result, isLoading } = useQuery({
    queryKey: ['users', pageIndex, perPage],
    queryFn: () => getUsers({ perPage, pageIndex }),
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  return (
    <div className="border-t border-gray-100 pt-5 dark:border-gray-750 md:border-t-0 md:pt-0">
      <div className="px-4 pb-5 md:flex md:px-0">
        <div className="flex flex-col">
          <div className="flex items-center gap-2">
            <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
              Gerenciamento de usuários
            </h1>
            <div className="rounded-full border border-purple-200 bg-purple-50 px-2 py-0.5 text-xs font-medium text-purple-600 dark:border-purple-800 dark:bg-purple-900 dark:text-purple-300">
              {layoutResult?.users || 0} usuários
            </div>
          </div>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-150">
            Gerencie usuários e suas permissões de conta aqui
          </p>
        </div>
        {roles?.isAdmin && (
          <div className="mt-4 flex gap-3 md:ml-auto md:mt-0 md:self-start">
            <CreateUserModal />
          </div>
        )}
      </div>
      <Table>
        <TableHeader>
          <TableHead>Nome</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Email</TableHead>
          <TableHead>Equipes</TableHead>
          <TableHead />
        </TableHeader>
        <TableBody>
          {result &&
            result.users.map((user) => (
              <TableRow key={user.id}>
                <TableCell className="whitespace-nowrap font-medium text-gray-900 dark:text-gray-25">
                  <div className="flex items-center gap-3">
                    <Avatar>{abbreviateName(user.name)}</Avatar>
                    {user.name}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex items-center">
                    {user.active ? (
                      <div className="flex items-center gap-1 rounded-full border border-green-200 bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 dark:border-green-400 dark:bg-green-900 dark:text-green-300">
                        <div className="h-1.5 w-1.5 rounded-full bg-green-600" />{' '}
                        Ativo
                      </div>
                    ) : (
                      <div className="flex items-center gap-1 rounded-full border border-gray-200 bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 dark:border-gray-725 dark:bg-gray-850 dark:text-gray-125">
                        <div className="h-1.5 w-1.5 rounded-full bg-gray-600 dark:bg-gray-350" />{' '}
                        Inativo
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <div className="flex gap-1">
                    {user.team.map((team) => (
                      <Tag key={team.id} variant={team.color}>
                        {team.name}
                      </Tag>
                    ))}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex justify-end">
                    {roles?.isAdmin && <EditUserModal user={user} />}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          {isLoading && <UsersTableSkeleton perPage={perPage} />}
        </TableBody>
      </Table>
      {result && (
        <Pagination
          onPageChange={handlePaginate}
          pageIndex={result.meta.pageIndex}
          totalCount={result.meta.totalCount}
          totalInPage={result.users.length}
          perPage={result.meta.perPage}
        />
      )}
    </div>
  )
}
