import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { CircleAlert, Plus, X } from 'lucide-react'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { getAlertStatus } from '../../../api/get-alert-status'
import { getAuthenticatedUser } from '../../../api/get-authenticated-user'
import { getServices } from '../../../api/get-services'
import { getServiceStatus } from '../../../api/get-services-status'
import { registerAlert } from '../../../api/register-alert'
import { Button } from '../../../components/Button'
import * as Combobox from '../../../components/Form/Combobox'
import * as Input from '../../../components/Form/Input'
import * as Select from '../../../components/Form/Select'
import { Textarea } from '../../../components/Form/Textarea'
import { Toast } from '../../../components/Toast'
import { Tooltip } from '../../../components/Tooltip'
import { abbreviateName } from '../../../lib/abbreviate'
import { queryClient } from '../../../lib/react-query'

export const createAlertSchema = z.object({
  title: z.string().min(3),
  services: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string(),
    }),
  ),
  serviceStatusId: z.string().uuid(),
  alertStatusId: z.string().uuid(),
  message: z.string().min(3),
})

export type CreateAlertProps = z.infer<typeof createAlertSchema>

export function CreateAlertModal() {
  const [open, setOpen] = useState<boolean>()

  const {
    reset,
    watch,
    control,
    handleSubmit,
    setValue,
    resetField,
    formState: { isValid },
  } = useForm<CreateAlertProps>({
    resolver: zodResolver(createAlertSchema),
  })

  const services = watch('services')

  const { data: result } = useQuery({
    queryKey: ['alert-status'],
    queryFn: () =>
      getAlertStatus({
        pageIndex: 0,
        perPage: 999,
      }),
  })

  const { data: serviceStatusResult } = useQuery({
    queryKey: ['services-status'],
    queryFn: getServiceStatus,
  })

  const { data: servicesResult } = useQuery({
    queryKey: ['services'],
    queryFn: () =>
      getServices({
        pageIndex: 0,
        perPage: 999,
      }),
  })

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
  })

  const { mutateAsync: registerAlertFn } = useMutation({
    mutationKey: ['register-alert'],
    mutationFn: registerAlert,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['alerts'] })
      queryClient.refetchQueries({ queryKey: ['layout-services-count'] })
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast t={t} title="Alerta criado com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar criar o alerta, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  async function handleCreateAlert(data: CreateAlertProps) {
    const { title, services, alertStatusId, serviceStatusId, message } = data

    registerAlertFn({
      title,
      services,
      alertStatusId,
      serviceStatusId,
      message,
      createdById: authenticatedUser!.user.id,
    })
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        setOpen(value)
        reset()
      }}
    >
      <Dialog.Trigger asChild>
        <Button className="flex items-center gap-1.5">
          <Plus className="h-5 w-5" /> Criar Alerta
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content
          tabIndex={-1}
          className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950 md:max-w-2xl"
        >
          <div className="flex items-start border-b border-gray-100 px-4 py-5 dark:border-gray-750 md:p-6">
            <div className="hidden rounded-md border border-gray-100 p-3 text-lg dark:border-gray-750 md:block">
              <CircleAlert className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <div className="md:ml-4">
              <Dialog.Title className="text-lg font-semibold text-gray-900 dark:text-gray-25">
                Registrar Alerta
              </Dialog.Title>
              <Dialog.Description className="text-sm text-gray-600 dark:text-gray-375">
                Descreva as informações do incidente abaixo
              </Dialog.Description>
            </div>
            <Dialog.Cancel
              tabIndex={-1}
              className="ml-auto rounded-lg p-2 hover:bg-gray-50 dark:hover:bg-gray-750"
            >
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <form
            id="createAlert"
            className="max-h-[60vh] overflow-y-auto py-6 md:max-h-none md:pb-0 md:pt-8"
            onSubmit={handleSubmit(handleCreateAlert)}
          >
            <div className="flex flex-col gap-4 px-6">
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  Título*
                </label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <Input.Root>
                      <Input.Control
                        {...field}
                        placeholder="e.g. Rompimento SL x SOL"
                      />
                    </Input.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  Serviços*
                </label>
                <div>
                  <Controller
                    name="services"
                    control={control}
                    render={({ field }) => (
                      <Combobox.Root {...field} multiple>
                        <Combobox.Trigger placeholder="Selecione os serviços" />

                        <Combobox.Content>
                          {servicesResult &&
                            servicesResult.services.map((service) => (
                              <Combobox.Option key={service.id} value={service}>
                                {service.name}
                              </Combobox.Option>
                            ))}
                        </Combobox.Content>
                      </Combobox.Root>
                    )}
                  />
                  <div
                    className={clsx('flex flex-wrap', {
                      'mt-4': services,
                    })}
                  >
                    {services &&
                      services.map((service) => (
                        <Tooltip
                          key={service.id}
                          description={service.name}
                          asChild
                        >
                          <div
                            onClick={() => {
                              const array = services.filter(
                                (serviceOnArray) =>
                                  service.id !== serviceOnArray.id,
                              )

                              if (array.length === 0) {
                                resetField('services')
                              } else {
                                setValue('services', array)
                              }
                            }}
                            className="!first:ml-0 -ml-1 flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full border border-white bg-purple-600 text-sm font-semibold text-white transition-colors hover:bg-purple-700 dark:border-gray-950"
                          >
                            {abbreviateName(service.name)}
                          </div>
                        </Tooltip>
                      ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  Status do serviço*
                </label>
                <Controller
                  name="serviceStatusId"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger>
                        <Select.Value placeholder="Selecione o status do serviço" />
                      </Select.Trigger>

                      <Select.Content>
                        {serviceStatusResult &&
                          serviceStatusResult.status.map((status) => (
                            <Select.Item key={status.id} value={status.id}>
                              <Select.ItemText>{status.name}</Select.ItemText>
                            </Select.Item>
                          ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  Status do alerta*
                </label>
                <Controller
                  name="alertStatusId"
                  control={control}
                  render={({ field }) => (
                    <Select.Root
                      {...field}
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <Select.Trigger>
                        <Select.Value placeholder="Selecione o status do alerta" />
                      </Select.Trigger>

                      <Select.Content>
                        {result &&
                          result.status.map((status) => (
                            <Select.Item key={status.id} value={status.id}>
                              <Select.ItemText>{status.name}</Select.ItemText>
                            </Select.Item>
                          ))}
                      </Select.Content>
                    </Select.Root>
                  )}
                />
              </div>
              <div className="flex flex-col gap-1.5 md:grid md:grid-cols-edit">
                <label className="text-sm font-medium text-gray-700 dark:text-gray-125">
                  Mensagem*
                </label>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      placeholder="e.g. descrição do incidente"
                    />
                  )}
                />
              </div>
            </div>
          </form>
          <div className="mt-6 flex flex-col-reverse gap-3 border-t border-gray-100 p-4 dark:border-gray-750 md:flex-row">
            <Dialog.Cancel asChild>
              <Button variant="outline" type="button" className="flex-1">
                Cancelar
              </Button>
            </Dialog.Cancel>
            <Button
              form="createAlert"
              type="submit"
              className="flex-1"
              disabled={!isValid}
            >
              Criar
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
