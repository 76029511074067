import * as Tabs from '@radix-ui/react-tabs'
import { useMutation, useQuery } from '@tanstack/react-query'
import { endOfDay, format, startOfDay, sub } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import _ from 'lodash'
import { Calendar, Download, Search } from 'lucide-react'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import { downloadMetricsReport } from '../../../api/download-metrics-report'
import { getMetrics } from '../../../api/get-metrics'
import { getRoles } from '../../../api/get-roles'
import { Button } from '../../../components/Button'
import { DateRange } from '../../../components/Form/DatePicker/Content'
import * as DatePicker from '../../../components/Form/DatePicker/index'
import * as Select from '../../../components/Form/Select'
import { TabItem } from '../../../components/TabItem'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { ThemeToggle } from '../../../components/ThemeToggle'
import { firstLetterToUppercase } from '../../../lib/upperCase'
import { MetricsHeaderTableSkeleton } from './metrics-header-table-skeleton'
import { MetricsTableSkeleton } from './metrics-table-skeleton'

export function GoogleSheets() {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const navigate = useNavigate()

  const [date, setDate] = useState<DateRange>({
    from: startOfDay(
      sub(new Date(), {
        days: 1,
      }),
    ),
    to: endOfDay(
      sub(new Date(), {
        days: 1,
      }),
    ),
  })

  const dateRange =
    firstLetterToUppercase(
      format(date.from ?? new Date(), 'MMM dd, yyyy', {
        locale: ptBR,
      }),
    ) +
    ' - ' +
    firstLetterToUppercase(
      format(date.to ?? new Date(), 'MMM dd, yyyy', {
        locale: ptBR,
      }),
    )

  const { data: result, isLoading } = useQuery({
    queryKey: ['get-metrics', date],
    queryFn: () =>
      getMetrics({
        from: date.from,
        to: date.to,
      }),
  })

  const { mutateAsync: downloadMetricsReportFn, isPending } = useMutation({
    mutationKey: ['download-metrics-report'],
    mutationFn: downloadMetricsReport,
    onSuccess: (data, variables) => {
      const fileStream = data

      const blob = new Blob([fileStream], { type: 'application/octet-stream' })

      const dataUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = dataUrl
      link.download = `${format(variables.from, 'MMMdd').toLowerCase()}-${format(variables.to, 'MMMdd').toLowerCase()}-metrics-report.xlsx`

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(dataUrl)
    },
  })

  function handleDownloadReport() {
    if (!date.from) return
    if (!date.to) return

    downloadMetricsReportFn({
      from: date.from,
      to: date.to,
    })
  }

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  if (roles) {
    const hasPermissions = roles.teams.some((team) =>
      team.permissions.some(
        (permission) => permission.name === 'integrationGoogleSheets',
      ),
    )

    if (!hasPermissions) {
      navigate('/')
      return null
    }
  }

  return (
    <>
      <div className="flex w-full flex-col justify-between px-4 md:flex-row md:px-0">
        <h1 className="text-3xl font-semibold text-gray-700 dark:text-gray-25">
          Google Sheets
        </h1>
        <div className="ml-auto">
          <ThemeToggle />
        </div>
      </div>
      <div className="px-4 md:px-0">
        {isMobile ? (
          <Select.Root defaultValue="slot">
            <Select.Trigger className="mb-4 mt-6">
              <Select.Value />
            </Select.Trigger>

            <Select.Content>
              <Select.Item value="slot">
                <Select.ItemText>Suporte Técnico Interno</Select.ItemText>
              </Select.Item>
            </Select.Content>
          </Select.Root>
        ) : (
          <Tabs.Root
            defaultValue="slot"
            className="hidden border-b border-gray-100 dark:border-gray-750 md:block"
          >
            <Tabs.List className="relative mt-6 flex w-full items-center gap-4 focus:shadow-none">
              <TabItem
                value="slot"
                title="Suporte Técnico Interno"
                isSelected
                layoutId="googleSheets"
                target="https://docs.google.com/spreadsheets/d/14xkAJXWFcYLImsJWDprSmARDvNTHdLdk3yfJ4lJ4UZw"
              />
            </Tabs.List>
          </Tabs.Root>
        )}
      </div>
      <div className="mx-4 md:mx-0 md:mt-6">
        <div className="mx-4 mt-8 md:mx-0 md:mt-0 md:flex">
          <div className="flex flex-1 flex-col md:flex-row">
            <div>
              <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
                Planilha de Retorno
              </h1>
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-375">
                Rastreie as métricas de cada colaborador.
              </p>
            </div>
            <div className="mt-4 flex items-start gap-3 md:ml-auto md:mt-0">
              <Button
                onClick={handleDownloadReport}
                variant="outline"
                className="flex items-center gap-1.5"
                disabled={isPending}
              >
                <Download className="h-5 w-5" />
                Exportar relatório
              </Button>
              <DatePicker.Root>
                <DatePicker.Trigger>
                  <Button
                    variant="outline"
                    className="flex items-center justify-center gap-1.5"
                  >
                    <Calendar className="h-5 w-5" />
                    {dateRange}
                  </Button>
                </DatePicker.Trigger>
                <DatePicker.Portal>
                  <DatePicker.Content
                    side="bottom"
                    align="end"
                    sideOffset={52}
                    max={new Date()}
                    state={date}
                    setState={setDate}
                  />
                </DatePicker.Portal>
              </DatePicker.Root>
            </div>
          </div>
        </div>
        <Table className="mt-6 table-fixed">
          <TableHeader>
            <TableHead />
            {result && result.metricsPerStatus.colaborators.length > 0 && (
              <>
                {result.metricsPerStatus.columns.map((column) => {
                  return (
                    <TableHead className="text-center" key={column}>
                      {column}
                    </TableHead>
                  )
                })}
                <TableHead className="w-48 text-center">
                  <p className="-translate-x-2">Total</p>
                </TableHead>
              </>
            )}
            {isLoading && <MetricsHeaderTableSkeleton />}
          </TableHeader>
          <TableBody>
            {result &&
              result.metricsPerStatus.colaborators.map((colaborator) => {
                return (
                  <TableRow key={colaborator.name}>
                    <TableCell className="font-medium text-gray-900 dark:font-normal dark:text-white">
                      {colaborator.name}
                    </TableCell>
                    {result.metricsPerStatus.columns.map((column) => {
                      return (
                        <TableCell className="text-center" key={column}>
                          {colaborator.status.find(
                            (status) => status.name === column,
                          )?.value || 0}
                        </TableCell>
                      )
                    })}
                    <TableCell className="text-center">
                      {colaborator.total}
                    </TableCell>
                  </TableRow>
                )
              })}
            {isLoading && <MetricsTableSkeleton />}
          </TableBody>
        </Table>
        {_.isEmpty(result?.metricsPerStatus.colaborators) && !isLoading && (
          <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
            <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
              <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
              Nenhum dado encontrado
            </h1>
            <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
              Sua pesquisa não encontrou nenhum registro de contato. <br />
            </p>
          </div>
        )}
      </div>
    </>
  )
}
