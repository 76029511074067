import { api } from '../lib/axios'

export interface GetStockItemsPerCategoryQuery {
  stockId: string | null
  description: string | null
}

export interface Item {
  id: number
  description: string
  amount: number
  metric: string
  stock: {
    id: number
    name: string
  }
  category: string
}

export interface GetStockItemsPerCategoryResponse {
  stock: {
    category: string
    items: Item[]
  }[]
}

export async function getStockItemsPerCategory({
  stockId,
  description,
}: GetStockItemsPerCategoryQuery) {
  const response = await api.get<GetStockItemsPerCategoryResponse>(
    '/stock/categorized',
    {
      params: {
        stockId,
        description,
      },
    },
  )

  return response.data
}
