import { api } from '../lib/axios'

export interface GetTicketQuery {
  id: string
}

export interface GetTicketResponse {
  ticket: {
    id: number
    title: string
    location: string
    priority: 'low' | 'medium' | 'high'
    description: string
    coordinates: string
    status: {
      id: string
      name: string
      type: 'wait' | 'analysis' | 'finished'
    }
    category: {
      id: string
      name: string
    }
    responsable: {
      id: string
      name: string
    }
    createdBy: {
      id: string
      name: string
    }
    user: {
      id: string
    }[]
    team: {
      id: string
      name: string
      color: 'purple' | 'green' | 'blue' | 'yellow' | 'red' | 'gray'
      leaders: {
        id: string
      }[]
    }
    created_at: string
    integrationId?: string
  }
  customerIXC?: string
  isResponsable: boolean
  isCreator: boolean
  isLeader: boolean
  isAbleToStock: boolean
}

export async function getTicket({ id }: GetTicketQuery) {
  const response = await api.get<GetTicketResponse>(`/tickets/${id}`)

  return response.data
}
