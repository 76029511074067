import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { Edit2, Plus, Search } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getRoles } from '../../api/get-roles'
import { getStockItems } from '../../api/get-stock-items'
import { Button } from '../../components/Button'
import { Pagination } from '../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/Table'
import { Tag } from '../../components/Tag'
import { ThemeToggle } from '../../components/ThemeToggle'
import { CreateStockItemModal } from './create-stock-item-modal'
import { EditStockItemModal } from './edit-stock-item-modal'
import { StockItemsTableSkeleton } from './stock-items-table-skeleton'

export function Stock() {
  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const category = searchParams.get('category')

  const { data: result, isLoading } = useQuery({
    queryKey: ['get-stock-items', category],
    queryFn: () =>
      getStockItems({
        pageIndex,
        perPage,
        category: category || null,
      }),
  })

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  return (
    <div className="flex-1">
      <div className="mx-4 flex flex-col gap-4 pt-8 md:mx-0 md:flex-row md:pt-0">
        <div className="flex flex-col gap-1">
          <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25 md:text-3xl">
            {category ?? 'Todos os itens'}
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150 md:text-base">
            Gerencie todos os itens armazenados em estoque.
          </p>
        </div>
        <div className="flex items-start gap-4 md:ml-auto">
          <ThemeToggle />
          <CreateStockItemModal>
            <Button className="flex items-center gap-1.5 py-2 md:py-3">
              <Plus className="h-5 w-5" />
              Criar
            </Button>
          </CreateStockItemModal>
        </div>
      </div>
      <Table className="mt-8">
        <TableHeader>
          <TableHead className="w-20">ID</TableHead>
          <TableHead>Descrição</TableHead>
          <TableHead>Estoque</TableHead>
          <TableHead>Categoria</TableHead>
          <TableHead>Quantidade</TableHead>
          <TableHead />
        </TableHeader>
        <TableBody>
          {result &&
            result.items.map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell className="font-mono font-medium text-gray-900 dark:text-gray-25">
                    #{item.id}
                  </TableCell>
                  <TableCell className="font-medium text-gray-900 dark:text-gray-25">
                    {item.description}
                  </TableCell>
                  <TableCell className="font-medium text-gray-900 dark:text-gray-25">
                    <Tag>{item.stock.name}</Tag>
                  </TableCell>
                  <TableCell>{item.category}</TableCell>
                  <TableCell>{`${item.amount} ${item.amount > 1 ? item.metric + 's' : item.amount === 0 ? item.metric + 's' : item.metric}`}</TableCell>
                  <TableCell>
                    {roles?.isAdmin && (
                      <div className="flex justify-end">
                        <EditStockItemModal item={item}>
                          <Button variant="ghost" className="p-2">
                            <Edit2 className="h-5 w-5" />{' '}
                          </Button>
                        </EditStockItemModal>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          {!result && isLoading && <StockItemsTableSkeleton perPage={8} />}
        </TableBody>
      </Table>
      {_.isEmpty(result?.items) && !isLoading && (
        <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
          <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
            <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
          </div>
          <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
            Nenhum item encontrado
          </h1>
          <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
            Tente novamente ou adicione um estoque.
          </p>
        </div>
      )}
      {result && (
        <Pagination
          onPageChange={handlePaginate}
          pageIndex={result.meta.pageIndex}
          totalCount={result.meta.totalCount}
          totalInPage={result.items.length}
          perPage={result.meta.perPage}
        />
      )}
    </div>
  )
}
