import { api } from '../lib/axios'

export interface UpdateStockItemBody {
  itemId: number
  description: string
  category: string
  amount: string
  metric: string
  stockId: string
}

export async function updateStockItem({
  itemId,
  description,
  category,
  amount,
  metric,
  stockId,
}: UpdateStockItemBody) {
  await api.patch('/stock/item', {
    itemId,
    description,
    category,
    amount,
    metric,
    stockId,
  })
}
