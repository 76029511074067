import * as TooltipDemo from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { ReactNode } from 'react'

interface TooltipProps extends TooltipDemo.TooltipContentProps {
  children: ReactNode
  description: string | ReactNode
  classNames?: {
    trigger?: string
    content?: string
  }
}

export function Tooltip({
  children,
  description,
  hidden,
  ...props
}: TooltipProps) {
  const { asChild, classNames, ...rest } = props

  return (
    <TooltipDemo.Provider>
      <TooltipDemo.Root>
        <TooltipDemo.Trigger className={classNames?.trigger} asChild={asChild}>
          {children}
        </TooltipDemo.Trigger>
        <TooltipDemo.Portal>
          <TooltipDemo.Content
            sideOffset={4}
            className={clsx(
              'max-w-xs rounded-lg bg-gray-950 px-3 py-2 text-center text-sm font-semibold text-white shadow-lg dark:bg-gray-850 md:max-w-lg',
              { hidden },
              classNames?.content,
            )}
            {...rest}
          >
            <TooltipDemo.Arrow className="fill-gray-950 dark:fill-gray-850" />
            {description}
          </TooltipDemo.Content>
        </TooltipDemo.Portal>
      </TooltipDemo.Root>
    </TooltipDemo.Provider>
  )
}
