import { api } from '../lib/axios'

export interface GetSettingsLayoutResponse {
  teams: number
  users: number
  stock: number
}

export async function getSettingsLayout() {
  const response = await api.get<GetSettingsLayoutResponse>('/layout/settings')

  return response.data
}
