import { api } from '../lib/axios'

export interface RegisterStockBody {
  name: string
  members?: {
    id: string
  }[]
}

export async function registerStock({ name, members }: RegisterStockBody) {
  await api.post('/stock', {
    name,
    members,
  })
}
