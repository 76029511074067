import { api } from '../lib/axios'

export interface GetStockItemsQuery {
  pageIndex?: number | null
  perPage?: number | null
  stockId?: string | null
  category?: string | null
}

export interface Item {
  id: number
  description: string
  amount: number
  metric: string
  stock: {
    id: number
    name: string
  }
  category: string
}

export interface GetStockItemsResponse {
  items: Item[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getStockItems({
  pageIndex,
  perPage,
  stockId,
  category,
}: GetStockItemsQuery) {
  const response = await api.get<GetStockItemsResponse>('/stock/items', {
    params: {
      perPage,
      pageIndex,
      stockId,
      category,
    },
  })

  return response.data
}
