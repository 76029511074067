import { zodResolver } from '@hookform/resolvers/zod'
import * as Tabs from '@radix-ui/react-tabs'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Package } from 'lucide-react'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { z } from 'zod'

import { getTicket } from '../../../api/get-ticket'
import { registerView } from '../../../api/register-view'
import { Button } from '../../../components/Button'
import * as Select from '../../../components/Form/Select'
import { Skeleton } from '../../../components/Skeleton'
import { TabItem } from '../../../components/TabItem'
import { EditTicketModal } from './edit-ticket-modal'
import { MessagesModal } from './message-modal'
import { StockModal } from './stock-modal'

const ticketTabSchema = z.object({
  tab: z.enum(['overview', 'participants', 'assets']),
})

type TicketTabSchema = z.infer<typeof ticketTabSchema>

export function TicketLayout() {
  const [searchParams] = useSearchParams()

  const location = useLocation()
  const navigate = useNavigate()

  const { ticketId } = useParams()

  const { data: result, isLoadingError } = useQuery({
    queryKey: ['ticket', ticketId],
    queryFn: () => getTicket({ id: ticketId ?? '' }),
  })

  const { control, watch, setValue } = useForm<TicketTabSchema>({
    resolver: zodResolver(ticketTabSchema),
  })

  const tab = watch('tab')

  useEffect(() => {
    const page = location.pathname.split('/')[3]

    if (page === 'comments') return

    if (!tab) {
      if (location.pathname) {
        setValue(
          'tab',
          ticketTabSchema.parse({
            tab: page,
          }).tab,
        )
      }
    } else {
      const parameters = new URLSearchParams(searchParams)

      if (tab === page) {
        navigate(`/tickets/${ticketId}/${tab}?${parameters.toString()}`)
      } else {
        navigate(`/tickets/${ticketId}/${tab}`)
      }
    }
  }, [tab])

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const ticket = result?.ticket

  useEffect(() => {
    if (isLoadingError) navigate('/tickets/my')
  }, [isLoadingError])

  const { mutateAsync: registerViewFn } = useMutation({
    mutationKey: ['register-view'],
    mutationFn: registerView,
  })

  useEffect(() => {
    if (!ticketId) return

    registerViewFn({
      ticket: {
        id: ticketId,
      },
    })
  }, [ticketId])

  if (location.pathname.includes('comments')) {
    return (
      <div>
        <Outlet />
      </div>
    )
  }

  return (
    <div>
      <div className="px-4 md:flex md:px-0">
        {ticket ? (
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-25">
            {ticket.title}
          </h1>
        ) : (
          <Skeleton className="h-8 w-48 rounded-md" />
        )}
        <div className="mt-4 flex gap-3 md:ml-auto md:mt-0 md:items-start">
          {result?.isAbleToStock && (
            <StockModal>
              <Button variant="outline" className="flex gap-1.5">
                <Package className="h-5 w-5" />
                Estoque
              </Button>
            </StockModal>
          )}
          {isMobile ? (
            <Button
              onClick={() => {
                navigate('comments')
              }}
              variant="outline"
            >
              Mensagens
            </Button>
          ) : (
            <MessagesModal />
          )}
          <EditTicketModal />
        </div>
      </div>
      <div className="px-4 md:px-0">
        {isMobile ? (
          <Controller
            name="tab"
            control={control}
            render={({ field }) => (
              <Select.Root
                {...field}
                value={field.value}
                onValueChange={field.onChange}
              >
                <Select.Trigger className="mb-8 mt-6">
                  <Select.Value />
                </Select.Trigger>

                <Select.Content>
                  <Select.Item value="overview">
                    <Select.ItemText>Detalhes</Select.ItemText>
                  </Select.Item>
                  <Select.Item value="participants">
                    <Select.ItemText>Participantes</Select.ItemText>
                  </Select.Item>
                  <Select.Item value="assets">
                    <Select.ItemText>Arquivos</Select.ItemText>
                  </Select.Item>
                </Select.Content>
              </Select.Root>
            )}
          />
        ) : (
          <Controller
            name="tab"
            control={control}
            render={({ field }) => (
              <Tabs.Root
                {...field}
                value={field.value}
                onValueChange={field.onChange}
                className="mb-8 border-b border-gray-100 dark:border-gray-750"
              >
                <Tabs.List className="relative mt-6 flex w-full items-center gap-4 focus:shadow-none">
                  <TabItem
                    title="Detalhes"
                    value="overview"
                    isSelected={tab === 'overview'}
                    layoutId="ticket"
                  />
                  <TabItem
                    title="Participantes"
                    value="participants"
                    isSelected={tab === 'participants'}
                    layoutId="ticket"
                  />
                  <TabItem
                    title="Arquivos"
                    value="assets"
                    isSelected={tab === 'assets'}
                    layoutId="ticket"
                  />
                </Tabs.List>
              </Tabs.Root>
            )}
          />
        )}
      </div>
      <Outlet />
    </div>
  )
}
