import { api } from '../lib/axios'

export interface GetStockQuery {
  pageIndex?: number | null
  perPage?: number | null
  stockId?: string | null
}

export interface Stock {
  id: number
  name: string
  active: boolean
  users: {
    id: string
    name: string
  }[]
}

export interface GetStockResponse {
  stock: Stock[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getStock({ pageIndex, perPage, stockId }: GetStockQuery) {
  const response = await api.get<GetStockResponse>('/stock', {
    params: {
      perPage,
      pageIndex,
      stockId,
    },
  })

  return response.data
}
