import { ReactNode } from 'react'
import innerText from 'react-innertext'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

import { Tooltip } from './Tooltip'

const tag = tv({
  base: [
    'max-w-full rounded-full border px-1.5 py-0.5 text-xs font-medium w-fit h-fit flex gap-0.5 items-center justify-center overflow-ellipsis',
  ],
  variants: {
    variant: {
      purple:
        'border-purple-200 bg-purple-50 text-purple-700 dark:bg-purple-900 dark:text-purple-300 dark:border-purple-800',
      green:
        'border-green-200 bg-green-50 text-green-700 dark:border-green-400 dark:bg-green-900 dark:text-green-300',
      blue: 'border-blue-200 bg-blue-50 text-blue-700 dark:bg-blue-900 dark:text-blue-300 dark:border-blue-500',
      yellow:
        'border-yellow-200 bg-yellow-50 text-yellow-700 dark:bg-yellow-900 dark:text-yellow-300 dark:border-yellow-500',
      red: 'border-red-200 bg-red-50 text-red-700 dark:bg-red-900 dark:text-red-300 dark:border-red-500',
      gray: 'border-gray-200 bg-gray-50 text-gray-700 dark:bg-gray-900 dark:text-gray-125 dark:border-gray-500',
    },
  },
  defaultVariants: {
    variant: 'purple',
  },
})

interface TagProps {
  variant?: 'purple' | 'green' | 'blue' | 'yellow' | 'red' | 'gray'
  className?: string
  children: ReactNode
}

export function Tag({ variant, children, className, ...props }: TagProps) {
  return (
    <Tooltip
      classNames={{ content: 'text-xs' }}
      description={innerText(children)}
      asChild
    >
      <div {...props} className={tag({ variant, className })}>
        <div className={twMerge('truncate', className)}>{children}</div>
      </div>
    </Tooltip>
  )
}
